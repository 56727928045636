import { graphql } from 'gatsby'
import React from 'react'
import Appointment from '../components/appointment'
import Copyright from '../components/copyright'
import Layout from '../components/layout'

const Page = ({ data }) => (
  <Layout>
    <Appointment vendors={data.frontpage.vendors.vendors} />
    {/* <SEO
      lang="de"
      title={data.page.yoast.title}
      description={data.page.yoast.metadesc}
    /> */}
    <section id="main">
      <div class="container">
        <header>
          <h1>{data.page.title}</h1>
        </header>
        <main
          dangerouslySetInnerHTML={{
            __html: data.page.content.replace(
              new RegExp('</br>', 'g'),
              '' // quick fix for a WordPress issue
            ),
          }}
        />
      </div>
    </section>
    <footer id="footer">
      <Copyright />
    </footer>
  </Layout>
)

export const query = graphql`
  query($id: String) {
    page: wpPage(id: { eq: $id }) {
      content
      title
    }
    frontpage: wpPage(id: { eq: "cG9zdDo2" }) {
      vendors {
        vendors {
          vendorSub
          vendorPrice
          vendorName
        }
      }
    }
  }
`

export default Page
