import React from 'react'

import { Link } from 'gatsby'

export default () => (
  <div id="copyright">
    <ul class="links">
      <li>&copy; Thermenwartung in Wien</li>
      <li>
        Basierend auf Dopetrope von <a href="http://html5up.net">HTML5 UP</a>
      </li>
      <li>
        <Link to="/impressum">Impressum</Link>
      </li>
      <li>
        <Link to="/datenschutz">Datenschutz</Link>
      </li>
    </ul>
  </div>
)
