import React from 'react'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class Appointment extends React.Component {
  constructor(props) {
    super(props)
    this.state = { ['anbieter']: this.props.vendors[0].vendorName }
  }

  handleChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({ [name]: value }) //https://reactjs.org/docs/forms.html#handling-multiple-inputs
  }

  handleSubmit = event => {
    event.preventDefault()
    const form = event.target
    console.log(
      encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      })
    )
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(res => {
        if (res.ok) return res
        else throw Error(res.statusText)
      })
      .then(() =>
        document
          .getElementById('contact-form')
          .classList.add('contact-form--success')
      )
      .catch(() =>
        document
          .getElementById('contact-form')
          .classList.add('contact-form--failure')
      )
  }

  onChangeSelect(event) {
    let option = event.target[event.target.selectedIndex].value

    let e = document.getElementsByClassName('vendor-info--active')

    for (let i = 0; i < e.length; i++) {
      e[i].classList.remove('vendor-info--active')
    }

    document
      .getElementById(option + '-div')
      .classList.add('vendor-info--active')
  }

  twofunctions = e => {
    this.onChangeSelect(e)
    this.handleChange(e)
  }

  render() {
    const [first, ...rest] = this.props.vendors
    return (
      <div>
        <input className="modal-control" id="modal" type="checkbox" hidden />
        <div className="modal">
          <label for="modal" className="modal-background"></label>
          <div className="modal-content">
            <label for="modal" role="button" className="modal-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 24 24"
              >
                <defs />
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
              </svg>
            </label>
            <div className="modal-header">
              <h2 className="m-0">Terminanfrage</h2>
            </div>
            <div className="modal-body">
              <form
                id="contact-form"
                className="contact-form"
                name="vendor-form"
                method="post"
                data-netlify="true"
                netlify-honeypot="bot-field-form"
                onSubmit={this.handleSubmit}
              >
                <input type="hidden" name="form-name" value="vendor-form" />
                <p className="hidden">
                  <label>
                    Don’t fill this out if you're human:{' '}
                    <input name="bot-field-form" onChange={this.handleChange} />
                  </label>
                </p>

                <header>
                  <div>
                    <p className="p-modal">
                      Bitte wählen Sie einen Anbieter aus:{' '}
                    </p>
                    <label>
                      <select
                        name="anbieter"
                        id="selector"
                        value={this.state['anbieter']}
                        onChange={this.twofunctions}
                      >
                        {this.props.vendors.map(vendor => (
                          <option
                            id={vendor.vendorName + '-option'} // TODO: remove
                            value={vendor.vendorName}
                            name={vendor.vendorName}
                          >
                            {vendor.vendorName} {vendor.vendorSub}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>
                  <br />
                  <article
                    id={`${this.props.vendors[0].vendorName}-div`}
                    class="vendor-info vendor-info--active"
                  >
                    <h3>{this.props.vendors[0].vendorName}</h3>
                    <strong>{this.props.vendors[0].vendorSub}</strong>
                    <p>
                      Thermenwartung{' '}
                      <strong>{this.props.vendors[0].vendorPrice}</strong>
                    </p>
                  </article>
                  {rest.map(vendor => (
                    <article
                      id={`${vendor.vendorName}-div`}
                      class="vendor-info"
                    >
                      <h3>{vendor.vendorName}</h3>
                      <strong>{vendor.vendorSub}</strong>
                      <p>
                        Thermenwartung <strong>{vendor.vendorPrice}</strong>
                      </p>
                    </article>
                  ))}

                  <div className="field">
                    <select
                      id="practice"
                      name="anwendung"
                      onChange={this.handleChange}
                    >
                      <option value="" selected=""></option>
                      <option value="Gasdurchlaufgerät">
                        {' '}
                        Gasdurchlaufgerät - 150€ für ca. 1,5 Stunden Arbeit{' '}
                      </option>
                      <option value="Gaskonvektor">
                        {' '}
                        Gaskonvektor - 150€ für ca. 1,5 Stunden Arbeit{' '}
                      </option>
                      <option value="Gaskessel">
                        {' '}
                        Gaskessel ab 25kW - 180€ für ca. 1,5 Stunden Arbeit{' '}
                      </option>
                      <option value="Brennwertgerät">
                        {' '}
                        Brennwertgerät - 200€ für ca. 2 Stunden Arbeit{' '}
                      </option>
                      <option value="Kombitherme">
                        {' '}
                        Kombitherme - 180€ für ca. 2 Stunden Arbeit{' '}
                      </option>
                    </select>
                  </div>
                </header>

                <div className="fields">
                  <div className="field">
                    <label for="wunschtermin">Wunschtermin</label>
                    <input
                      className="datefields"
                      type="date"
                      id="wunschtermin"
                      name="wunschtermin"
                      required="required"
                      min={new Date().toISOString().substring(0, 10)}
                      placeholder="tt.mm.jjjj"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="field half">
                    <input
                      type="checkbox"
                      id="w_vm"
                      name="w_vm"
                      defaultChecked={true}
                      onChange={this.handleChange}
                    />
                    <label for="w_vm">vormittags</label>
                  </div>
                  <div className="field half">
                    <input
                      type="checkbox"
                      id="w_nm"
                      name="w_nm"
                      defaultChecked={true}
                      onChange={this.handleChange}
                    />
                    <label for="w_nm">nachmittags</label>
                  </div>
                </div>
                <div className="fields">
                  <div className="field">
                    <label for="ersatztermin">Ersatztermin</label>
                    <input
                      className="datefields"
                      type="date"
                      id="ersatztermin"
                      name="ersatztermin"
                      required="required"
                      min={new Date().toISOString().substring(0, 10)}
                      placeholder="tt.mm.jjjj"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="field half">
                    <input
                      type="checkbox"
                      id="e_vm"
                      name="e_vm"
                      defaultChecked={true}
                      onChange={this.handleChange}
                    />
                    <label for="e_vm">vormittags</label>
                  </div>
                  <div className="field half">
                    <input
                      type="checkbox"
                      id="e_nm"
                      name="e_nm"
                      defaultChecked={true}
                      onChange={this.handleChange}
                    />
                    <label for="e_nm">nachmittags</label>
                  </div>
                </div>

                <h3>Ihre Kontaktdaten</h3>
                <div className="fields">
                  <div className="field">
                    <label for="title">Anrede</label>
                    <select
                      id="title"
                      name="anrede"
                      required="required"
                      onChange={this.handleChange}
                    >
                      <option value="" selected=""></option>
                      <option value="Herr"> Herr </option>
                      <option value="Frau"> Frau </option>
                    </select>
                  </div>
                  <div className="field">
                    <label for="firstname">Vorname</label>
                    <input
                      id="firstname"
                      type="text"
                      name="firstname"
                      required="required"
                      placeholder="Vorname"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="field">
                    <label for="lastname">Nachname</label>
                    <input
                      id="lastname"
                      type="text"
                      name="nachname"
                      required="required"
                      placeholder="Nachname"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="field">
                    <label for="street">Straße &amp; Nr.</label>
                    <input
                      id="street"
                      type="text"
                      name="strasse"
                      required="required"
                      placeholder="Straße"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="field">
                    <label for="zip">PLZ</label>
                    <input
                      id="zip"
                      type="number"
                      name="plz"
                      required="required"
                      placeholder="PLZ"
                      min="1000"
                      max="9999"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="field">
                    <label for="phone">Telefon</label>
                    <input
                      id="phone"
                      type="text"
                      name="telefon"
                      required="required"
                      placeholder="Telefon"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="field">
                    <label for="email">E-Mail</label>
                    <input
                      id="email"
                      type="email"
                      name="email"
                      required="required"
                      placeholder="E-Mail"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <h3>Wie möchten Sie kontaktiert werden?</h3>
                <div className="fields">
                  <div className="field half">
                    <input
                      type="checkbox"
                      id="k_telefon"
                      name="k_telefon"
                      defaultChecked={true}
                      onChange={this.handleChange}
                    />
                    <label for="k_telefon">per Telefon</label>
                  </div>
                  <div className="field half">
                    <input
                      type="checkbox"
                      id="k_email"
                      name="k_email"
                      defaultChecked={true}
                      onChange={this.handleChange}
                    />
                    <label for="k_email">per E-Mail</label>
                  </div>
                </div>

                <h3>
                  Angaben zum Gerät{' '}
                  <span className="gray-optional">(optional)</span>
                </h3>
                <div className="fields">
                  <div className="field">
                    <label for="brand">Marke</label>
                    <input
                      id="brand"
                      type="text"
                      name="marke"
                      placeholder="z.B. Vaillant"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="field">
                    <label for="type">Type</label>
                    <input
                      id="type"
                      type="text"
                      name="type"
                      placeholder="z.B. ecoTEC plus VC"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="field">
                    <label for="lastService">Letzte Wartung</label>
                    <input
                      id="lastService"
                      type="text"
                      name="wartung"
                      placeholder="z.B. 11/2018"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <h3>Wie möchten Sie bezahlen?</h3>
                <div className="fields">
                  <div className="field">
                    <label for="payment">Bevorzugte Zahlungsmöglichkeit</label>
                    <select
                      id="payment"
                      name="Bezahlung"
                      onChange={this.handleChange}
                    >
                      <option value="KA" selected>
                        Keine Auswahl
                      </option>
                      <option value="Barzahlung"> Barzahlung </option>
                      <option value="Kartenzahlung"> Kartenzahlung </option>
                    </select>
                  </div>
                </div>
                <div className="fields">
                  <div className="field">
                    <input
                      type="checkbox"
                      id="privacy"
                      required="required"
                      onChange={this.handleChange}
                    />
                    <label for="privacy">
                      Ja, ich möchte zwecks Terminvereinbarung kontaktiert
                      werden und willige ein, dass meine Daten zu diesem Zweck
                      verarbeitet werden. Ich kann meine Einwilligung jederzeit
                      per E-Mail widerrufen, dann werden meine Daten umgehend
                      gelöscht.
                    </label>
                  </div>
                </div>
                <button name="submit" type="submit">
                  Terminanfrage senden
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Appointment
